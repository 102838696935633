var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.parentCategories.length)?_c('article',{staticClass:"project-page-adaptation"},[_c('h2',{staticClass:"project-page-title"},[_vm._v("Адаптация")]),_c('div',{staticClass:"project-page-adaptation__tabs"},[_c('button',{staticClass:"btn",class:{
        'btn--blue': _vm.activeSubCategory === null,
        'btn--white': _vm.activeSubCategory !== null,
      },attrs:{"type":"button"},on:{"click":function($event){_vm.activeSubCategory = null}}},[_vm._v("\n      Все фотографии\n    ")]),_vm._l((_vm.childs),function(c,i){return _c('button',{key:i,staticClass:"btn",class:{
        'btn--blue': _vm.activeSubCategory === c.id,
        'btn--white': _vm.activeSubCategory !== c.id,
      },attrs:{"type":"button"},on:{"click":function($event){_vm.activeSubCategory = c.id}}},[_vm._v("\n      "+_vm._s(c.title)+"\n    ")])})],2),(_vm.images.length)?_c('SliderComponent',{attrs:{"items":_vm.images,"slider-options":_vm.sliderOptions},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(item.category.type.code === 1)?_c('MockupComponent',{attrs:{"data":item.img,"device":"phone"}}):_vm._e(),(item.category.type.code === 2)?_c('MockupComponent',{attrs:{"data":item.img,"device":"pc"}}):_vm._e()]}}],null,false,2338188417)}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }